import { supabase } from "../config/supabase"
import { PAGE_COUNT } from "../constants/db"

export const getCourseMetric = (course, metric, userId) => {
    return supabase.from(metric).select('*', { count: 'exact', head: true }).eq('user_id', userId).eq('lesson_id', course)
}

export const getCourses = () => {
    return supabase.from('lessons').select()
}

export const getQuestions = (batch_id, lesson_id) => {
    return supabase.from('questions').select('*').eq('lesson_id', lesson_id).eq('batch_id', batch_id)
}

export const getQuestionsCount = (lesson_id, batch_id) => {
    return supabase.from('questions').select('*', { count: 'exact', head: true }).eq('lesson_id', lesson_id).eq('batch_id', batch_id).eq('isLive', true)
}

export const getOptions = (question_id) => {
    return supabase.from('options').select('*').eq('question_id', question_id)
}

export const deleteLesson = (lesson_id) => {
    return supabase.from('lessons').delete().eq('id', lesson_id)
}

export const updateModule = (id, data) => {
    return supabase.from('question batch').update(data).eq('lesson_id', id)
}

export const deleteModule = (id) => {
    return supabase.from('question batch').delete().eq('id', id)
}

export const createOption = (option, question_id, lesson_id, is_correct, explanation) => {
    return supabase.from('options').insert({
        name: option,
        is_correct,
        lesson_id,
        question_id,
        explanation,
    })
}

export const deleteOption = (option_id) => {
    console.log(option_id)
    return supabase.from('options').delete().eq('id', option_id)
}

export const getOptionsCount = (lesson_id) => {
    return supabase.from('options').select('*', { count: 'exact', head: true }).eq('lesson_id', lesson_id)
}

export const getModules = () => {
    return supabase.from('question_batch_tens').select('*', { count: 'exact', head: true })
}

export const getAllUsers = () => {
    return supabase.from('App Users').select()
}

export const getAnsweredQuestions = (id) => {
    return supabase.from('answered questions').select("*", { count: 'exact', head: true }).eq('user_id', id)
}

export const addQuestion = (question, scenario, dialogue, lesson_id, batch_id) => {
    return supabase.from('questions').insert({
        question,
        lesson_id,
        batch_id,
        batches: 1,
        question_batch_ten: 1,
        scenario,
        dialogue,
    }).select()
}

export const deleteQuestion = (question_id, lesson_id) => {
    return supabase.from('questions').delete().eq('id', question_id)
}

export const updateCorrectness = (option_id, val) => {
    return supabase.from('options').update({ is_correct: val }).eq('id', option_id)
}

export const getFailedQuestions = (id) => {
    return supabase.from('failed questions').select("*", { count: 'exact', head: true }).eq('user_id', id)
}

export const getCompletedQuestions = (id) => {
    return supabase.from('completed').select("*", { count: 'exact', head: true }).eq('user_id', id)
}

export const getCategories = (courseId) => {
    return supabase.from('question batch').select().eq('lesson_id', courseId)
}

export const addCourse = (name) => {
    return supabase.from('lessons').insert({
        name,
        questions_count: 0,
        description: null,
        isLive: true,
    })
}


export const addModule = (name, lesson_id) => {
    return supabase.from('question batch').insert({
        name,
        lesson_id,
        isLive: true,
        total_questions: 0,
        x: 0,
        is_first: false,
        isScenario: name.toLowerCase().indexOf('scenario') > -1,
    }).select()
}

export const getPendingPaddies = (accepted) => {
    return supabase.from('App Users').select().eq('type', 'paddy').eq('isApproved', accepted).eq('declined_break', false)
}

export const updatePaddy = (id, accept) => {
    let body = { id, rejected: !accept }

    return supabase.functions.invoke('approvePaddy', { body })
}

export const decideInternship = (id, decision) => supabase.functions.invoke('decideInternship', { body: { id, decision } })

export const getInitBuddies = () => {
    return supabase.from('App Users').select().eq('type', 'buddy')
    // .limit(PAGE_COUNT)
}

export const getBuddies = (offset) => {
    const from = offset * PAGE_COUNT
    const to = from + PAGE_COUNT - 1
    return supabase.from('App Users').select().eq('type', 'buddy').range(from, to)
}

export const getBuddyApprovedSessions = () => {
    return supabase.from('bookings').select()
}

export const requestForInternship = () => {
    return supabase.from('App Users').select().eq('type', 'buddy').eq('status', 'Yes').eq('internship_accepted', false)
}

export const getRequestingSessionsFromBuddy = () => {
    return supabase.from('notifications').select().eq('type', 'session request')
}

export const getReviewCount = (type) => {
    return supabase.from('ratings').select('*', { head: true, count: 'exact' }).eq('type', type)
}

export const getBuddyReviews = () => {
    return supabase.from('ratings').select().eq('type', 'buddy')
}

export const getPaddyReviews = () => {
    return supabase.from('ratings').select().eq('type', 'paddy')
}

export const getPaddyCount = (accepted) => {
    return supabase.from('App Users').select('*', { count: 'exact', head: true }).eq('type', 'paddy').eq('isApproved', accepted).eq('declined_break', false)
}

export const getBuddyCount = () => {
    return supabase.from('App Users').select('*', { count: 'exact', head: true }).eq('type', 'buddy')
}

export const getRequestingSessionsFromBuddyCount = () => {
    return supabase.from('notifications').select('*', { count: 'exact', head: true }).eq('type', 'session request')
}

export const getApprovedSessionCount = () => {
    return supabase.from('bookings').select('*', { count: 'exact', head: true })
}

export const getIntershipCount = () => {
    return supabase.from('App Users').select('*', { count: 'exact', head: true }).eq('type', 'buddy').eq('status', 'Yes').eq('internship_accepted', false)
}

export const rejectIntership = (id) => {
    return supabase.from('App Users').update({ status: 'No' }).eq('id', id)
}

export const acceptInternship = (id) => {
    return supabase.from('App Users').update({ status: 'Yes', internship_accepted: true }).eq('id', id)
}