import { useEffect, useState } from "react"
import PaddyReview from "./paddy"
import BuddyReview from "./buddy"
import { getReviewCount } from "../../services/db"

const Index = () => {
    const [tags, setTags] = useState({
        tags: ["Paddy Review", "Buddy Review"],
        selected: "Paddy Review",
        paddyReviewCount: 0,
        buddyReviewCount: 0,
    })

    useEffect(() => {
        getReviewCount('paddy').then((res) => setTags((prev) => ({ ...prev, paddyReviewCount: res.count })))
        getReviewCount('buddy').then((res) => setTags((prev) => ({ ...prev, buddyReviewCount: res.count })))
    }, [])

    return (
        <div className="pt-10">
            <div className="flex items-center gap-4">
                {tags.tags.map((a, i) => <div
                    onClick={() => setTags((prev) => ({ ...prev, selected: a }))}
                    className={tags.selected === a ? "cursor-pointer text-white bg-blue-400 py-1 px-4 rounded-full font-medium" : "cursor-pointer text-black font-medium"}>
                    {a} ({i === 0 ? tags.paddyReviewCount : tags.buddyReviewCount})
                </div>)
                }
            </div>

            {tags.selected === "Paddy Review" && <PaddyReview />}
            {tags.selected === "Buddy Review" && <BuddyReview />}
        </div>
    )
}

export default Index