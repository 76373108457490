import { Outlet } from "react-router-dom"
import NavBar from "./components/nav/navbar"

const DashboardRoot = () => {
    return (
        <div>
            <NavBar />
            <div className="pt-12">
                <Outlet />
            </div>
        </div>
    )
}

export default DashboardRoot