import { createBrowserRouter } from "react-router-dom";
import Login from './pages/login'
// import Register from './pages/register'
import NotFound from './pages/404'
import Root from "./dashboard_root";
import Dashboard from './pages/dashboard'
import PrivateRoute from "./privateRoute";

import Buddies from './pages/buddies'
import Reviews from './pages/reviews'
import Progress from './pages/progress'

import ActiveUsers from './pages/active users'
import Chat from './pages/chat'
import ChatPage from "./pages/chat/page";
import Courses from './pages/courses'
import Questions from "./pages/courses/questions";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [

            // {
            //     path: "register",
            //     element: <Register />,
            // },
            {
                path: "",
                element: <PrivateRoute children={<Dashboard />} />,
            },
            {
                path: "buddies",
                element: <Buddies />
            },
            {
                path: "reviews",
                element: <Reviews />
            },
            {
                path: "progress",
                element: <Progress />
            },
            {
                path: "active",
                element: <ActiveUsers />
            },
            {
                path: "chat",
                element: <Chat />
            },
            {
                path: "chat/:id",
                element: <ChatPage />
            },
            {
                path: "courses",
                element: <Courses />
            },
            {
                path: "courses/:id",
                element: <Questions />
            }
        ],
        errorElement: <NotFound />
    },
    {
        path: "/login",
        element: <Login />,
    },
]);