import { useEffect, useState } from "react"
import { getAllUsers } from "../../services/db"
import { useNavigate } from "react-router-dom"

const Index = () => {
    const navigate = useNavigate()
    const [users, setUsers] = useState([])

    useEffect(() => {
        getAllUsers().then((res) => setUsers(res.data))
    }, [])

    // realtime..

    // 2 way notification

    return (
        <div className="pt-10">
            {users.map((user, i) => (
                <div className="flex items-center justify-between h-20 px-3 mb-3">
                    <div>
                        <div className="text-base font-medium">{user.full_name}</div>
                        <div className="text-sm text-gray-400">{user.email}</div>

                        <div className="mt-1 bg-blue-500 text-white rounded-3xl py-1 w-20 text-center text-sm">{user.type}</div>
                    </div>
                    <button
                        onClick={() => navigate(`/chat/${user.id}`)}
                        className="text-sm font-medium text-black transition-all ease-in duration-300 underline hover:underline-offset-8">Message</button>
                </div>
            ))}
        </div>
    )
}

export default Index