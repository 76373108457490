import { useEffect, useState } from "react"
import { getPaddyReviews } from "../../services/db"
import { useModalStore } from "../../store/modal"
import Review from "../../components/modals/review"
import Moment from "react-moment"

const PaddyReview = () => {
    const { setOpen } = useModalStore()
    const [reviews, setReviews] = useState([])

    useEffect(() => {
        getPaddyReviews().then((res) => setReviews([...res.data]))
    }, [])

    return (
        <div>
            <div className='bg-white shadow py-2 px-4 mx-4 rounded-xl'>
                {reviews.map((review, i) => (
                    <div key={i} className='flex items-center justify-between mb-2'>
                        <div>{review.buddyname} reviewed {review.paddyname} on <Moment format='D/MM/YY'>{review.createdAt}</Moment></div>
                        <button
                            onClick={() => setOpen('Buddy Review', <Review review={review} />)}
                            className='text-blue-400 hover:text-blue-500 transition-all ease-out duration-300 hover:underline underline-offset-4'
                        >View More</button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PaddyReview