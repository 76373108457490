import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { addQuestion, getQuestions } from "../../services/db";
import QuestionList from "./question_list";

const Questions = () => {
    const { id } = useParams()
    const [searchParams, setSetParams] = useSearchParams()

    const [questions, setQuestions] = useState([])

    useEffect(() => {
        getQuestions(id, searchParams.get('lesson')).then((a) => setQuestions(a.data))
    }, [])

    const addNewQuestion = async () => {
        const question = prompt("Enter a question")
        if (question != null) {
            await addQuestion(question, id)
            window.location.reload()
        }
    }

    return (
        <div className="py-6 px-4">
            <div className="flex items-center justify-between">
                <div className="text-xl font-medium pb-3">Questions</div>
                <button className="text-blue-500 font-medium" onClick={addNewQuestion}>Add Question</button>
            </div>

            {questions.map((question, index) => <QuestionList index={index} question={question} key={index} />)}
        </div>
    )
}

export default Questions;