import { toast } from "react-toastify"
import Button from "../button"
import { updatePaddy } from "../../services/db"

const PaddyModal = ({ paddy }) => {
    const accept = async () => {
        try {
            const { error } = await updatePaddy(paddy.id, true)

            if (error) {
                return toast(error.message, { type: 'error', hideProgressBar: true })
            }

            toast('Paddy accepted', { hideProgressBar: true })
            window.location.reload()
        } catch (e) {
            toast(e)
        }
    }

    const decline = async () => {
        try {
            const { error } = await updatePaddy(paddy.id, false)

            if (error) {
                return toast(error.message, { type: 'error', hideProgressBar: true })
            }

            toast('Paddy accepted', { hideProgressBar: true })
            window.location.reload()
        } catch (e) {
            toast(e)
        }
    }

    return (
        <div>
            <div className="overflow-y-auto h-96 mb-2">
                <div className="flex items-center justify-center">
                    <img className="h-32 w-32 object-cover rounded-full" src={paddy.image} alt={paddy.full_name} />
                </div>
                <div className="text-xl font-medium mt-5 mb-1">{paddy.full_name}</div>
                <div>Last login: {paddy.last_login ?? 'N/A'}</div>

                <div className="text-sm">Email: {paddy.email}</div>
                <div className="text-sm">Bio: {paddy.bio}</div>
                <div className="text-sm">Location: {paddy.location}</div>
                <div className="text-sm">Degree: {paddy.degree}</div>
                <div className="text-sm">Experience: {paddy.experience} years</div>

                <ul>
                    <div className="text-base mt-2 font-medium">Services</div>

                    {paddy.services && paddy.services.map((service) => (<li className="text-sm">{service}</li>))}
                </ul>

                <ul>
                    <div className="text-base mt-3 font-medium">Available dates</div>

                    {paddy.available_dates && paddy.available_dates.map((service) => (<li className="text-sm">{service}</li>))}
                </ul>

                <ul>
                    <div className="text-base mt-3 font-medium">Available times</div>

                    {paddy.available_time && paddy.available_time.map((service) => (<li className="text-sm">{service}</li>))}
                </ul>
            </div>

            {!paddy.isApproved && <Button
                className="w-full rounded-md bg-red-500 px-3 my-2 py-2 text-red-500 font-semibold text-white shadow-sm hover:bg-red-600 text-sm"
                text={'Decline'}
                onClick={decline}
            />}

            {!paddy.isApproved && <Button
                className="w-full rounded-md bg-blue-600 px-3 my-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
                text={'Accept'}
                onClick={accept}
            />}
        </div>
    )
}

export default PaddyModal