import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./context/auth";

export default function PrivateRoute({ children, ...rest }) {
    let auth = useAuth()

    if (!auth.user) {
        return <Navigate to="/login" replace />;
    }

    return children;
}
