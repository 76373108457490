import { useRef, useState, useEffect } from "react";
import { requestForInternship } from "../../services/db";
import { useModalStore } from "../../store/modal";
import BuddyInfo from "../../components/modals/buddy";

const RequestedInternship = () => {
    const [items, setItems] = useState([]);
    const containerRef = useRef(null)
    const { setOpen } = useModalStore()

    useEffect(() => {
        requestForInternship().then((res) => setItems([...res.data]))
    }, [])

    return (
        <>
            <div className="bg-white shadow rounded-xl mt-4 py-3 px-4 grid grid-cols-4 gap-4 items-start">
                <div>Name</div>
                <div>Email</div>
                <div>User ID</div>
                <div></div>
            </div>

            <div className="bg-white shadow rounded-xl mt-4 px-4" ref={containerRef}>
                {items.map((user, i) => (
                    <div key={i} className="grid grid-cols-4 gap-4 py-4 px-2 items-start">
                        <div className="truncate">{user.full_name}</div>
                        <div className="truncate">{user.email}</div>
                        <div className="truncate">{user.id}</div>
                        <button
                            onClick={() => setOpen('Buddy Profile', <BuddyInfo
                                buddy={user}
                                isInternship={true}
                            />)}
                            className="text-blue-500 text-sm font-medium hover:text-blue-700"
                        >View Profile</button>
                    </div>
                ))}
            </div>
        </>
    )
}

export default RequestedInternship