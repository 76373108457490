import { useState } from "react"
import { login, signOut } from "../../services/auth"
import { toast } from 'react-toastify'
import Background from '../../assets/bg/bg.png'

import { useNavigate } from 'react-router-dom'
import Button from "../../components/button"

const Index = () => {
    const navigate = useNavigate()
    const [data, setData] = useState({
        email: "",
        password: ""
    })

    const updateValue = (e) => {
        const { name, value } = e.target
        setData((prev) => ({ ...prev, [name]: value }))
    }

    const signIn = async () => {
        if (data.email !== 'mycareerpaddy@gmail.com') {
            return toast('Invalid Admin credentials', { type: 'error', hideProgressBar: true })
        }

        try {
            const res = await login(data.email, data.password)
            if (res.error !== null) {
                return toast(res.error.message, { theme: 'dark', hideProgressBar: true, type: 'error' })
            }

            if (res.data.session.user.id !== '85dc8ee4-89db-49af-af70-d7bf4e4e170d') {
                await signOut()
                return toast('Not an admin', { type: 'error', hideProgressBar: true })
            }

            navigate('/')
        } catch (e) {
            toast(e.toString())
        }
    }

    return (
        <div
            style={{ background: `url(${Background}) 100% no-repeat` }}
            className="flex items-center h-screen max-h-screen justify-center flex-col">
            <div className="bg-white shadow-sm rounded-2xl flex flex-col justify-center items-center py-4 px-12 w-3/4 lg:w-1/3">
                <h3 className="font-semibold text-2xl pt-14">Login to Account</h3>

                <p className="text-sm font-medium pt-4 pb-10">Please enter your email and password to continue</p>

                <input
                    id="email"
                    name="email"
                    type={'email'}
                    className="text-sm font-semibold bg-gray-200 py-3 pl-3 rounded-lg border outline-none border-gray-100 w-full"
                    placeholder="Email"
                    value={data.email}
                    onChange={updateValue}
                />

                <input
                    id="password"
                    name="password"
                    type={'password'}
                    className="text-sm font-semibold bg-gray-200 py-3 pl-3 rounded-lg border outline-none border-gray-100 w-full my-4"
                    placeholder="Password"
                    value={data.password}
                    onChange={updateValue}
                />

                <Button
                    text={'Sign In'}
                    className="bg-blue-600 font-semibold mt-4 mb-3 w-3/4 py-3 px-4 rounded-lg text-white"
                    onClick={signIn}
                />

                <div className="lg:flex items-center gap-1 mt-2 mb-6">
                    <div className="text-sm font-semibold">Don’t have access?</div>
                    <a href="mailto:mycareerpaddy@gmail.com" className="text-sm text-blue-600 font-medium">Contact Career Paddy</a>
                </div>
            </div>
        </div >
    )
}

export default Index