import { Link } from 'react-router-dom'
import Background from '../../assets/bg/bg.png'
import Icon from '../../assets/svg/404.svg'
import { ReactSVG } from 'react-svg'

const Index = () => {
    return (
        <div
            style={{ background: `url(${Background}) 100% no-repeat` }}
            className="flex items-center h-screen max-h-screen justify-center flex-col"
        >
            <div className="bg-white shadow-sm rounded-2xl flex flex-col justify-center items-center py-4 px-4 w-1/3">
                <div className='my-8' />
                <ReactSVG src={Icon} />
                <div className='text-xl font-semibold mt-10 mb-3'>Looks like you’ve got lost….</div>
                <Link to={'/'} className='bg-blue-500 font-medium text-white text-center decoration-none text-base py-2 px-4 rounded-xl w-3/5 mt-3 mb-3'>Back to Dashboard</Link>
            </div>
        </div>
    )
}

export default Index