import { supabase, supabaseAdmin } from '../config/supabase'

export const createChat = (buddy, paddy) => supabase.functions.invoke('addChat', { body: { buddy, paddy } })

export const sendMessage = async (chat_id, text, sender, paddy, buddy) => {
    await supabaseAdmin.from('messages').insert({
        chat_id,
        text,
        sender,
        paddy,
        buddy
    })

    await supabaseAdmin
        .from('chats')
        .update({ last_message: text })
        .eq('chat_id', chat_id)

    const body = {
        text,
        chat_id,
        senderUid: sender,
        buddy,
        paddy
    }

    return supabaseAdmin.functions.invoke('chatNotification', { body })
}

export const getChats = (buddy, paddy) => {
    return supabaseAdmin.from('messages').select().eq('buddy', buddy).eq('paddy', paddy)
}

export const sendNotification = (senderUid, buddy, paddy, chat_id, text) => {
    return supabase.functions.invoke('chatNotification', { body: { senderUid, buddy, paddy, chat_id, text } })
}