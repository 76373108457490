import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { createChat, getChats, sendMessage, sendNotification } from "../../services/chat"
import { supabase } from "../../config/supabase"
import ChatBubble from "./bubble"
import './style.css'
import { toast } from "react-toastify"
import { useAuth } from "../../context/auth"

const ChatPage = () => {
    const { user } = useAuth()
    const { id } = useParams()
    const [userId, setUserId] = useState(null)
    const [chatId, setChatId] = useState(null)

    const [chats, setChats] = useState([])

    const [newMessage, setNewMessage] = useState('');

    const handleInputChange = (event) => {
        setNewMessage(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await sendMessage(chatId, newMessage, userId, userId, id)
        setNewMessage('');
        await sendNotification(userId, id, userId, chatId, newMessage)
    };

    const fetch = async () => {
        const res = await createChat(id, user.id)
        setChatId(res.data.chat_id)

        setUserId(user.id)
        const { data } = await getChats(id, user.id)
        setChats(data.map(a => ({ text: a.text, side: userId == a.sender ? 'right' : 'left' })))
    }

    const updateObject = (id, updatedProps) => {
        setChats((prevArray) => {
            const existingIndex = prevArray.findIndex((obj) => obj.id === id);

            if (existingIndex !== -1) {
                // Update existing object
                return prevArray.map((obj, index) => (
                    index === existingIndex ? { ...obj, ...updatedProps } : obj
                ));
            } else {
                // Add new object
                return [...prevArray, { id, ...updatedProps }];
            }
        });
    }


    useEffect(() => {
        toast('Loading chats', { hideProgressBar: true })
        const res = supabase
            .channel('room1')
            .on('postgres_changes', { event: '*', schema: 'public', table: 'messages' }, payload => {
                console.log(payload)
                updateObject(payload.new.message_id, payload.new)
            })
            .subscribe()

        setTimeout(() => fetch(), 800)

        return () => {
            res.unsubscribe()
        }
    }, [id])

    return (
        <div className='pt-10 chat-container'>
            <div className='chat-window'>
                {chats.map((message, index) => (
                    <ChatBubble key={index} text={message.text} side={message.side} />
                ))}
            </div>
            <form className='chat-input-form' onSubmit={handleSubmit}>
                <input
                    type='text'
                    value={newMessage}
                    onChange={handleInputChange}
                    placeholder='Type your message here'
                />
                <button type='submit'>Send</button>
            </form>
        </div>
    )
}

export default ChatPage