import { useEffect, useState } from "react"
import { getActiveUsers } from "../../services/auth"

const Index = () => {
    const [users, setUsers] = useState([])

    useEffect(() => {
        getActiveUsers().then((res) => setUsers(res))
    }, [])

    return (
        <div className="pt-10">
            <div>Users who opened the app yesterday</div>

            {users.map((user) => <div>{user.full_name}</div>)}
        </div>
    )
}

export default Index