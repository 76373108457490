import React from 'react';

function ChatBubble({ text, side }) {
    return (
        <div className={`chat-bubble ${side}`}>
            <p>{text}</p>
        </div>
    );
}

export default ChatBubble;