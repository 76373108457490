import { useEffect, useState, useRef } from "react"
import { addModule, addQuestion, createOption, getCategories, updateModule } from "../../services/db"
import { Link } from "react-router-dom"
import * as XLSX from 'xlsx';
import CategoryList from "./category_list"
import { toast } from "react-toastify";

const CourseList = ({ course }) => {
    const [data, setData] = useState({
        categories: 0,
    })
    const ref = useRef(null)

    const [categories, setCategories] = useState([])

    const handleFile = async (e) => {
        const files = e.target.files;
        for (var j = 0; j < files.length; j++) {
            var file = files[j]
            const fileName = file.name.split('.xlsx')[0];
            const { data } = await addModule(fileName, course.id)
            toast(`Created module: ${fileName}...Uploading questions kindly wait...`, { type: 'success' })

            const cat = data[0]

            const fileData = await file.arrayBuffer();
            const workbook = XLSX.read(fileData);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                defval: "",
            });

            for (let i = 0; i < jsonData.length; i++) {
                const item = jsonData[i]
                const question = item[cat.isScenario ? 3 : 0]
                const scenario = cat.isScenario ? item[1] : null
                const dialogue = cat.isScenario ? item[2] : null
                const option1 = item[cat.isScenario ? 4 : 1].replace('a) ', '').replace('b) ', '').replace('c) ', '').replace('d) ', '').trim()
                const option2 = item[cat.isScenario ? 5 : 2].replace('a) ', '').replace('b) ', '').replace('c) ', '').replace('d) ', '').trim()
                const option3 = item[cat.isScenario ? 6 : 3].replace('a) ', '').replace('b) ', '').replace('c) ', '').replace('d) ', '').trim()
                // const option4 = item[4].replace('a) ', '').replace('b) ', '').replace('c) ', '').replace('d) ', '').trim()
                const answer = item[cat.isScenario ? 7 : 4]

                let correctOption = null


                console.log(question)
                console.log(scenario)

                if (question != '' && question != 'Question' && question != undefined && question != null) {
                    if (answer.toLowerCase().indexOf('answer c') > -1) {
                        correctOption = option3
                    }

                    // if (answer.indexOf('Answer: d') > -1) {
                    //     correctOption = option4
                    // }

                    if (answer.toLowerCase().indexOf('answer b') > -1) {
                        correctOption = option2
                    }

                    if (answer.toLowerCase().indexOf('answer a') > -1) {
                        correctOption = option1
                    }

                    console.log(correctOption)

                    const q = await addQuestion(question, scenario, dialogue, cat.lesson_id, cat.id)
                    const qid = q.data[0].id

                    await createOption(option1, qid, cat.lesson_id, correctOption == option1, answer)
                    await createOption(option2, qid, cat.lesson_id, correctOption == option2, answer)
                    await createOption(option3, qid, cat.lesson_id, correctOption == option3, answer)
                    // await createOption(option4, qid, category.lesson_id, correctOption == option4, answer)
                }
            }

            updateModule(cat.id, { total_questions: jsonData.length })
            toast(`Questions added sucessfully at: ${fileName}`, { type: 'success', hideProgressBar: true })

        }
    }

    // const addCat = async () => {
    //     // const cat = prompt("Enter module name")
    //     // if (cat != null) {
    //     //     await addModule(cat, course.id)
    //     //     window.location.reload()
    //     // }
    // }

    useEffect(() => {
        getCategories(course.id).then((a) => {
            setCategories(a.data)
            setData((prev) => ({ ...prev, categories: a.data.length }))
        })
    }, [])

    return (
        <div className="pt-4">
            <div className="flex items-center justify-between">
                <div>
                    <div className="text-lg font-medium">{course.name}</div>
                    <div>
                        <div className="flex items-center gap-3 mb-2">
                            <div className="text-sm">Categories: {data.categories}</div>
                            <input
                                type="file"
                                ref={ref}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                className="hidden"
                                multiple
                                onChange={(e) => handleFile(e)}
                            />
                            <button onClick={() => ref.current.click()} className="bg-blue-500 py-1 px-3 text-white rounded-full text-sm font-medium">Add</button>
                        </div>

                        {categories.map((a) => <CategoryList category={a} key={a.id} />)}
                    </div>
                </div>
                {/* <Link to={`/courses/${course.id}`} className="text-base text-blue-500 font-medium hover:underline transition-all ease-in duration-300">View questions</Link> */}
            </div>
        </div>
    )
}

export default CourseList;