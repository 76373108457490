import AllBuddies from "./all"
import RequestedBuddies from './requested'
import Approved from './approved'
import Internship from './internships'
import { useEffect, useState } from 'react'
import { getApprovedSessionCount, getBuddyCount, getIntershipCount, getRequestingSessionsFromBuddyCount } from "../../services/db"

const Index = () => {
    const [tags, setTags] = useState({
        selected: 'All',
        allCount: 0,
        requestedSessions: 0,
        approvedSessions: 0,
        requestedInterships: 0,
        lists: [
            'All',
            'Requested Sessions',
            'Approved Sessions',
            'Requested Internship'
        ]
    })

    useEffect(() => {
        setTimeout(() => {
            getBuddyCount().then((res) => setTags((prev) => ({ ...prev, allCount: res.count })))
            getRequestingSessionsFromBuddyCount().then((res) => setTags((prev) => ({ ...prev, requestedSessions: res.count })))
            getApprovedSessionCount().then((res) => setTags((prev) => ({ ...prev, approvedSessions: res.count })))
            getIntershipCount().then((res) => setTags((prev) => ({ ...prev, requestedInterships: res.count })))
        }, 1400)
    }, [])

    const getCount = (i) => {
        if (i === 0) {
            return tags.allCount
        }

        if (i === 1) {
            return tags.requestedSessions
        }

        if (i === 2) {
            return tags.approvedSessions
        }

        else {
            return tags.requestedInterships
        }
    }

    return (
        <div className="h-screen max-h-screen bg-gray-100 w-full">
            <div className="flex gap-4 items-center pt-10 px-10 text-center truncate w-full overflow-x-auto">
                {tags.lists.map((tag, i) => <div
                    onClick={() => setTags((prev) => ({ ...prev, selected: tag }))}
                    className={tag === tags.selected ? "text-sm text-white bg-black py-1 px-4 rounded-full cursor-pointer" : "cursor-pointer"}
                >{tag} ({getCount(i)})</div>)}
            </div>

            {tags.selected === 'All' && <AllBuddies />}
            {tags.selected === 'Requested Sessions' && <RequestedBuddies />}
            {tags.selected === 'Approved Sessions' && <Approved />}
            {tags.selected === 'Requested Internship' && <Internship />}
        </div>
    )
}

export default Index