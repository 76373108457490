import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { ToastContainer } from 'react-toastify';
import Modal from "./components/dialog";
import Loader from './components/loader'
import ProvideAuth from "./context/auth";
import { useEffect, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setTimeout(() => setReady(true), 1400)
  }, [])

  return (
    <ProvideAuth>
      {!ready && <Loader />}
      {ready && <RouterProvider router={router} />}
      <ToastContainer position="bottom-right" />
      <Modal />
    </ProvideAuth>
  );
}

export default App;
