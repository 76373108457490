import { useEffect, useState } from "react"
import { getPendingPaddies } from "../../services/db"
import PaddyModal from "../../components/modals/paddy"
import { useModalStore } from "../../store/modal"
import { toast } from 'react-toastify'

const PendingPaddies = () => {
    const { setOpen } = useModalStore()
    const [users, setUsers] = useState(null)

    const fetchData = async () => {
        const { data, error } = await getPendingPaddies(false)
        if (error) {
            toast(error.message)
        }

        setUsers(data)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <div className="bg-white shadow rounded-xl mt-4 py-3 px-4 grid grid-cols-4 gap-4 items-start">
                <div>Name</div>
                <div>Email</div>
                <div>User ID</div>
                <div></div>
            </div>

            <div className="bg-white shadow rounded-xl mt-4 px-4">
                {users && users.map((user, i) => (
                    <div key={i} className="grid grid-cols-4 gap-4 py-4 px-2 items-start">
                        <div className="truncate">{user.full_name}</div>
                        <div className="truncate">{user.email}</div>
                        <div className="truncate">{user.id}</div>
                        <button
                            onClick={() => setOpen('Paddy Profile', <PaddyModal paddy={user} />)}
                            className="text-blue-500 text-sm font-medium hover:text-blue-700"
                        >View Profile</button>
                    </div>
                ))}
            </div>
        </>
    )
}

export default PendingPaddies;