import { supabase, supabaseAdmin } from "../config/supabase"

export const signUp = (email, password, username) => {
    return supabase.auth.signUp({
        email,
        password,
        options: {
            data: {}
        }
    })
}

export const login = (email, password) => {
    return supabase.auth.signInWithPassword({
        email,
        password
    })
}

export const signOut = () => supabase.auth.signOut()

export const getSession = () => supabase.auth.getSession();

export const getActiveUsers = async () => {
    const today = new Date()
    const mod = today.setDate(today.getDay() - 1)

    const yesterday = new Date(mod)

    const { data, error } = await supabase
        .from('App Users')
        .select()
        .gt('last_login', yesterday.toISOString());

    return data
}