import { useState, useRef } from 'react';
import { getBuddyApprovedSessions } from '../../services/db';
import { useEffect } from 'react';

const Approved = () => {
    const [items, setItems] = useState([]);
    const containerRef = useRef(null)

    useEffect(() => {
        getBuddyApprovedSessions().then((res) => {
            console.log(res.data)
            setItems([...res.data])
        })
    }, [])

    return (
        <>
            <div className="bg-white shadow rounded-xl mt-4 py-3 px-4 grid grid-cols-4 gap-4 items-start">
                <div>Buddy Name</div>
                <div>Paddy Name</div>
                <div>Type</div>
                <div>Time</div>
            </div>

            <div className="bg-white shadow rounded-xl mt-4 px-4" ref={containerRef}>
                {items.map((user, i) => (
                    <div key={i} className="grid grid-cols-4 gap-4 py-4 px-2 items-start">
                        <div className="truncate">{user.buddyname}</div>
                        <div className="truncate">{user.paddyname}</div>
                        <div className="truncate">{user.summary}</div>
                        <div className="truncate">{user.timestamp}</div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Approved