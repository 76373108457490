import { useState, useRef } from 'react';
import { getBuddies, getRequestingSessionsFromBuddy } from '../../services/db';
import { useEffect } from 'react';
import { debounce } from 'lodash'
import { PAGE_COUNT } from '../../constants/db';
import { useModalStore } from '../../store/modal';
import BuddyInfo from '../../components/modals/buddy';

const RequestedBuddies = () => {
    const [items, setItems] = useState([]);
    const containerRef = useRef(null)
    const { setOpen } = useModalStore()

    useEffect(() => {
        getRequestingSessionsFromBuddy().then((res) => setItems([...res.data]))
    }, [])

    return (
        <>
            <div className="bg-white shadow rounded-xl mt-4 py-3 px-4 grid grid-cols-5 gap-4 items-start">
                <div>Buddy</div>
                <div>Buddy Email</div>
                <div>Paddy</div>
                <div>Paddy Email</div>
                <div></div>
            </div>

            <div className="bg-white shadow rounded-xl mt-4 px-4" ref={containerRef}>
                {items.map((user, i) => (
                    <div key={i} className="grid grid-cols-5 gap-4 py-4 px-2 items-start">
                        <div className="truncate">{user.sender_name}</div>
                        <div className="truncate">{user.buddy_email}</div>
                        <div className="truncate">{user.user_name}</div>
                        <div className="truncate">{user.paddy_email}</div>
                        <button
                            onClick={() => setOpen('Buddy Profile', <BuddyInfo buddy={user} />)}
                            className="text-blue-500 text-sm font-medium hover:text-blue-700"
                        >View Profile</button>
                    </div>
                ))}
            </div>
        </>
    )
}

export default RequestedBuddies