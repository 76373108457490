import React, { useContext, createContext, useState, useEffect } from "react";
import { supabase } from "../config/supabase";

const authContext = createContext();

export default function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

export function useAuth() {
    return useContext(authContext);
}

function useProvideAuth() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        supabase.auth.onAuthStateChange((event, session) => {
            if (session != null) {
                setUser(session.user)
            } else {
                setUser(null)
            }
        })
    }, [])

    const signin = cb => {
        setUser("user");
        return cb();
    };

    const signout = cb => {
        setUser(null);
        return cb();
    };

    return {
        user,
        signin,
        signout
    };
}
