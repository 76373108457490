import { InfinitySpin } from 'react-loader-spinner'

const Index = () => {
    return (
        <div className="flex items-center justify-center h-screen">
            <InfinitySpin
                visible={true}
                width="200"
                color="#3498db"
                ariaLabel="infinity-spin-loading"
            />
        </div>
    )
}

export default Index