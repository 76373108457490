import { useEffect, useRef, useState } from "react"
import { addQuestion, createOption, deleteModule, getQuestionsCount, updateModule } from "../../services/db"
import { Link } from "react-router-dom"
import * as XLSX from 'xlsx';

const CategoryList = ({ category }) => {
    const ref = useRef(null)
    const [data, setData] = useState({
        questions: 0,
    })

    let currentTopic = category.name

    useEffect(() => {
        getQuestionsCount(category.lesson_id, category.id).then((a) => setData(() => ({ questions: a.count })))
    }, [])

    const handleFile = async (e) => {
        const file = e.target.files[0];
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            defval: "",
        });

        for (let i = 0; i < jsonData.length; i++) {
            const item = jsonData[i]

            // const topic = item[0]
            // console.log(topic)
            // currentTopic = topic == '' ? currentTopic : topic

            const question = item[category.isScenario ? 3 : 0]
            const scenario = category.isScenario ? item[1] : null
            const dialogue = category.isScenario ? item[2] : null
            const option1 = item[category.isScenario ? 4 : 1].replace('a) ', '').replace('b) ', '').replace('c) ', '').replace('d) ', '').trim()
            const option2 = item[category.isScenario ? 5 : 2].replace('a) ', '').replace('b) ', '').replace('c) ', '').replace('d) ', '').trim()
            const option3 = item[category.isScenario ? 6 : 3].replace('a) ', '').replace('b) ', '').replace('c) ', '').replace('d) ', '').trim()
            // const option4 = item[4].replace('a) ', '').replace('b) ', '').replace('c) ', '').replace('d) ', '').trim()
            const answer = item[category.isScenario ? 7 : 4]

            let correctOption = null


            console.log(question)
            console.log(scenario)

            if (question != '' && question != 'Question' && question != undefined && question != null) {
                if (answer.toLowerCase().indexOf('answer c') > -1) {
                    correctOption = option3
                }

                // if (answer.indexOf('Answer: d') > -1) {
                //     correctOption = option4
                // }

                if (answer.toLowerCase().indexOf('answer b') > -1) {
                    correctOption = option2
                }

                if (answer.toLowerCase().indexOf('answer a') > -1) {
                    correctOption = option1
                }

                console.log(correctOption)

                const q = await addQuestion(question, scenario, dialogue, category.lesson_id, category.id)
                const qid = q.data[0].id

                await createOption(option1, qid, category.lesson_id, correctOption == option1, answer)
                await createOption(option2, qid, category.lesson_id, correctOption == option2, answer)
                await createOption(option3, qid, category.lesson_id, correctOption == option3, answer)
                // await createOption(option4, qid, category.lesson_id, correctOption == option4, answer)
            }

        }

        alert('Questions added successfully')
        updateModule(category.id, { total_questions: jsonData.length })
    }

    return (
        <div className="pb-2">
            <div className="text-base font-medium">{category.name}</div>
            <div className="flex items-center gap-2">
                <div className="text-xs">Questions: {data.questions}</div>
                <Link className="text-blue-500 font-medium text-xs" to={`/courses/${category.id}?lesson=${category.lesson_id}`}>View</Link>
            </div>
            <input
                type="file"
                ref={ref}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                className="hidden"
                onChange={(e) => handleFile(e)}
            />
            <button onClick={() => ref.current.click()} className="text-xs">Upload XLSX</button>

            <br />
            <button onClick={async () => {
                await deleteModule(category.id)
                window.location.reload()
            }} className="text-xs bg-red-500 text-white py-1 px-4 mt-1 rounded-full">Delete</button>
        </div>
    )
}

export default CategoryList