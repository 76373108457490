import { useEffect, useState } from "react"
import { addCourse, getCourses } from "../../services/db"
import CourseList from "./course_list"

const Index = () => {
    const [lessons, setLessons] = useState([])

    const addNewCourse = async () => {
        const course = prompt("Enter new course title")
        if (course != null) {
            await addCourse(course)
            window.location.reload()
        }
    }

    useEffect(() => {
        getCourses().then((a) => setLessons(a.data))
    }, [])

    return (
        <div className="pt-5 px-6">
            <div className="flex items-center justify-between">
                <div className="text-2xl">All Courses</div>
                <button onClick={addNewCourse} className="text-white bg-blue-500 py-1 px-3 rounded-full text-sm font-medium">Add Course</button>
            </div>

            {lessons.map((lesson) => <CourseList key={lesson.id} course={lesson} />)}
        </div>
    )
}

export default Index