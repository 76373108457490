import { useEffect, useState } from "react"
import { createOption, deleteOption, deleteQuestion, getOptions, updateCorrectness } from "../../services/db"

const QuestionList = ({ question, index }) => {
    const [options, setOptions] = useState([])

    useEffect(() => {
        getOptions(question.id).then((a) => setOptions(a.data))
    }, [])

    const addOption = async () => {
        const option = prompt("Enter an option")
        if (option != null) {
            await createOption(option, question.id, question.lesson_id)
            window.location.reload()
        }
    }

    const deleteQuest = async () => {
        await deleteQuestion(question.id, question.lesson_id)
        window.location.reload()
    }

    return (
        <div>
            <div className="flex items-center justify-between">
                <div className="flex items-start gap-3 py-1">
                    <div>{index + 1}</div>
                    <div>{question.question}</div>
                </div>
                <button onClick={deleteQuest} className="text-red-500 font-medium">Delete</button>
            </div>

            <div className="flex items-center justigy-between gap-3">
                <div className="text-base font-medium">Options</div>
                <button onClick={addOption} className="text-blue-500 font-medium text-sm">Add an option</button>
            </div>
            {options.map((option) => <div className="flex items-center gap-3">
                <li key={option.id}>{option.name}</li>
                {option.is_correct && <div className="text-white bg-blue-500 py-1 px-3 text-sm rounded-full">Correct</div>}
                <button onClick={async () => {
                    await updateCorrectness(option.id, !option.is_correct)
                    window.location.reload()
                }}>update correct</button>
                <button
                    onClick={async () => {
                        await deleteOption(option.id);
                        window.location.reload();
                    }}
                    className="text-sm text-red-500 font-medium">Delete</button>
            </div>)}
        </div>
    )
}

export default QuestionList