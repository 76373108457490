import { toast } from "react-toastify"
import { decideInternship, rejectIntership } from "../../services/db"
import Button from "../button"

const BuddyInfo = ({ buddy, isInternship = false }) => {
    const decline = async () => {
        try {
            const { error } = await decideInternship(buddy.id, 'reject')

            if (error) {
                return toast(error.message, { type: 'error', hideProgressBar: true })
            }

            toast('Intership rejected', { hideProgressBar: true })
            window.location.reload()
        } catch (e) {
            toast(e)
        }
    }

    const accept = async () => {
        try {
            const { error } = await decideInternship(buddy.id, 'accept')

            if (error) {
                return toast(error.message, { type: 'error', hideProgressBar: true })
            }

            toast('Internship accepted', { hideProgressBar: true })
            window.location.reload()
        } catch (e) {
            toast(e)
        }
    }

    return (
        <div>
            <div className="overflow-y-auto h-96 mb-2">
                <div className="flex items-center justify-center">
                    <img className="h-32 w-32 object-cover rounded-full" src={buddy.image} alt={buddy.full_name} />
                </div>
                <div className="text-xl font-medium mt-5 mb-1">{buddy.full_name}</div>
                <div className="text-sm">Last login: {buddy.last_login ?? 'N/A'}</div>
                <div className="text-sm">Email: {buddy.email}</div>
                <div className="text-sm">Bio: {buddy.bio}</div>
                <div className="text-sm">Location: {buddy.location}</div>
                <div className="text-sm">Degree: {buddy.degree}</div>
                <div className="text-sm">Resume: <a className="text-blue-500 text-sm underline font-normal" href={buddy.resume} target="_blank">View</a></div>

                <div className="text-sm">University: {buddy.university}</div>
                <div className="text-sm">Sessions: {buddy.no_session}</div>
                <div className="text-sm">Job roles: {buddy.job_role}</div>
                <div className="text-sm">Employer: {buddy.employer}</div>

                <div className="text-sm">Courses completed: {buddy.courses_completed}</div>
                <div className="text-sm">Correct answers: {buddy.correct_answers}</div>
                <div className="text-sm">Incorrect answers: {buddy.incorrect_answers}</div>

                <ul>
                    <div className="text-base mt-2 font-medium">Skills</div>

                    {buddy.skills && buddy.skills.map((skill) => (<li className="text-sm">{skill}</li>))}
                </ul>
            </div>

            {isInternship && <div>
                <Button
                    className="w-full rounded-md bg-red-500 px-3 my-2 py-2 text-red-500 font-semibold text-white shadow-sm hover:bg-red-600 text-sm"
                    text={'Decline'}
                    onClick={decline}
                />

                <Button
                    className="w-full rounded-md bg-blue-600 px-3 my-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
                    text={'Accept'}
                    onClick={accept}
                />
            </div>}
        </div>

    )
}

export default BuddyInfo