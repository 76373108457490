import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import OneSignal from 'react-onesignal'
import { getSession } from "../../services/auth"
import PendingPaddies from '../paddies/pending'
import ApprovedPaddies from '../paddies/approved'
import { getPaddyCount } from '../../services/db'

const Index = () => {
    const [filters, setFilters] = useState({
        lists: ['Approved', 'Pending'],
        current: 'Approved',
        approvedCount: 0,
        pendingCount: 0,
    })

    const [oneSignalInitialized, setOneSignalInitialized] = useState(false)

    const initializeOneSignal = async () => {
        const { data, error } = await getSession()

        if (error) {
            toast(error.message, { type: 'error' })
        }

        if (oneSignalInitialized) {
            return
        }

        setOneSignalInitialized(true)
        await OneSignal.init({
            appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
            notifyButton: {
                enable: true,
            },
            allowLocalhostAsSecureOrigin: true,

        })

        await OneSignal.login(data.session.user.id)
    }

    useEffect(() => {
        initializeOneSignal()
        getPaddyCount(true).then((a) => setFilters((prev) => ({ ...prev, approvedCount: a.count })))
        getPaddyCount(false).then((a) => setFilters((prev) => ({ ...prev, pendingCount: a.count })))
    }, [])

    return (
        <div className="h-screen max-h-screen bg-gray-100 w-full">
            <div className="px-4 pt-8">
                <h3 className="text-2xl font-semibold">Paddies</h3>

                <div className='flex items-center gap-2 mt-3'>
                    {filters.lists.map((fil, i) => <div
                        onClick={() => setFilters((prev) => ({ ...prev, current: fil }))}
                        className={fil === filters.current ? 'bg-blue-100 rounded-full py-1 px-3 text-sm text-blue-600 cursor-pointer' : 'cursor-pointer text-sm bg-gray-200 text-black rounded-full py-1 px-3'}
                    >{fil} ({i === 0 ? filters.approvedCount : filters.pendingCount})</div>)}
                </div>

                {filters.current === 'Pending' && <PendingPaddies />}
                {filters.current === 'Approved' && <ApprovedPaddies />}
            </div>
        </div>
    )
}

export default Index