const Review = ({ review }) => {
    return (
        <div>
            <div>Review ID: {review.id}</div>

            <div>Buddy: {review.buddyname}</div>
            <div>Paddy: {review.paddyname}</div>

            <div>Explanation: {review.explanation}</div>
            <div>Message: {review.message}</div>

            <div>Harassment: {review.harassment}</div>

            <div>Did Session hold: {review.quality_check}</div>

            <div>Did you achieve your goal: {review.services_rendered}</div>

            <div>Improvement: {review.improvement}</div>
            <div>Improvement for Paddy: {review.paddy_improve}</div>

            <div>Rating: {review.rating} / 5</div>
            <div>Date: {review.createdAt}</div>
        </div>
    )
}

export default Review