import { useEffect, useState } from "react"
import { getAllUsers, getCourses, getModules } from "../../services/db"
import User from './user'

const Index = () => {
    const [courses, setCourses] = useState([])
    const [users, setUsers] = useState([])
    const [modules, setModules] = useState(0)

    const [rawCourses, setRawCourses] = useState([])

    useEffect(() => {
        getCourses().then((res) => {
            setRawCourses(res.data)
            const courses = res.data.map((a) => a.name)
            setCourses(courses)
        })
        getAllUsers().then((res) => setUsers(res.data))
        getModules().then((r) => setModules(r.count))
    }, [])

    return (
        <div className="px-4 lg:px-8">
            <div className="text-xl font-medium my-5">All Users</div>

            <div className="overflow-y-auto">
                {users.length > 0 && rawCourses.length > 0 && users.map((user) => <User
                    user={user}
                    rawCourses={rawCourses}
                    courses={courses}
                    modules={modules}
                />)}
            </div>
        </div>
    )
}

export default Index