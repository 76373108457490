import { useState, useRef } from 'react';
import { getBuddies, getInitBuddies } from '../../services/db';
import { useEffect } from 'react';
import { debounce } from 'lodash'
import { PAGE_COUNT } from '../../constants/db';
import { useModalStore } from '../../store/modal';
import BuddyInfo from '../../components/modals/buddy';

const AllBuddies = () => {
    const [items, setItems] = useState([]);
    const containerRef = useRef(null)
    const [offset, setOffset] = useState(1)
    const [isInView, setIsInView] = useState(false)

    const [isLast, setIsLast] = useState(false)

    const { setOpen } = useModalStore()

    useEffect(() => {
        getInitBuddies().then((res) => setItems([...res.data]))
    }, [])

    const handleScroll = (container) => {
        if (containerRef.current && typeof window !== 'undefined') {
            const container = containerRef.current
            const { bottom } = container.getBoundingClientRect()
            const { innerHeight } = window
            setIsInView((prev) => bottom <= innerHeight)
        }
    }

    useEffect(() => {
        const handleDebouncedScroll = debounce(() => !isLast && handleScroll(), 200)
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])


    useEffect(() => {
        if (isInView) {
            loadMoreUsers(offset)
        }
    }, [isInView])

    const loadMoreUsers = async (offset) => {
        setOffset((prev) => prev + 1)
        const { data } = await getBuddies(offset)

        if (data.length < PAGE_COUNT) {
            setIsLast(true)
        }

        setItems((prevTickets) => [...prevTickets, ...data])
    }

    return (
        <>
            <div className="bg-white shadow rounded-xl mt-4 py-3 px-4 grid grid-cols-4 gap-4 items-start">
                <div>Name</div>
                <div>Email</div>
                <div>User ID</div>
                <div></div>
            </div>

            <div className="bg-white shadow rounded-xl mt-4 px-4" ref={containerRef}>
                {items.map((user, i) => (
                    <div key={i} className="grid grid-cols-4 gap-4 py-4 px-2 items-start">
                        <div className="truncate">{user.full_name}</div>
                        <div className="truncate">{user.email}</div>
                        <div className="truncate">{user.id}</div>
                        <button
                            onClick={() => setOpen('Buddy Profile', <BuddyInfo buddy={user} />)}
                            className="text-blue-500 text-sm font-medium hover:text-blue-700"
                        >View Profile</button>
                    </div>
                ))}
            </div>
        </>
    )
}

export default AllBuddies