import { useEffect, useState } from "react"
import { getAnsweredQuestions, getCompletedQuestions, getCourseMetric, getFailedQuestions } from "../../services/db"
import { useModalStore } from "../../store/modal"
import Chart from "./chart.js"
import { toast } from "react-toastify"

const User = ({ user, rawCourses, courses, modules }) => {
    const [data, setData] = useState({
        answered: 0,
        unanswered: 0,
        completed: 0,
    })

    const { setOpen } = useModalStore()

    const load = async () => {
        toast('Loading user data. Please wait...')
        const ans = [], fai = []
        for (let i = 0; i < rawCourses.length; i++) {
            var { id } = rawCourses[i]

            const a = await getCourseMetric(id, 'answered questions', user.id)
            const f = await getCourseMetric(id, 'failed questions', user.id)

            ans.push(a.count)
            fai.push(f.count)
        }

        setOpen('Chart', <Chart
            failed={fai}
            answered={ans}
            courses={rawCourses}
        />)
    }

    const open = () => load()

    useEffect(() => {
        getAnsweredQuestions(user.id).then((res) => setData((prev) => ({ ...prev, answered: res.count })))
        getFailedQuestions(user.id).then((res) => setData((prev) => ({ ...prev, unanswered: res.count })))
        getCompletedQuestions(user.id).then((res) => setData((prev) => ({ ...prev, completed: res.count })))
    }, [])

    return (
        <div className="flex items-start justify-between pb-3">
            <div>
                <div className="text-base font-semibold">{user.full_name}</div>
                <div className="text-sm text-gray-400">Current Learning Points: {user.learning_points}</div>
                <div className="text-sm text-gray-400">Answered: {data.answered} Failed: {data.unanswered}</div>
                <div className="text-sm text-gray-400">Completed Courses: {data.completed}</div>
                <div className="text-sm text-gray-400">Completed Modules: {user.next_batch ?? 0} / {modules}</div>
            </div>

            <button className="bg-blue-500 py-2 px-5 rounded-2xl text-sm text-white" onClick={open}>View Chart</button>
        </div>
    )
}

export default User